import data from '../../fields';

export default {
  requiredFields: () => {
    return [
      'area_id',
      'address_district_id',
      'address_full',
      'geo_polygon',
      'audit_doc_number',
      'audit_doc_date',
      'file_audit_doc_files',
      'file_audit_doc_photos',
      'object_functioning_mark'
    ];
  },

  sections: (object, areaId) => {
    function getFiles(folder) {
      return object.files.filter(el => el.folder_code == folder);
    }

    const geoPoint = object.geo_polygon?.[0]?.coordinates?.[0]?.[0];

    const getMapZoom = () => {
      if (geoPoint) return 18;
      return 10;
    };

    return [
      {
        title: 'ВЫЯВЛЕНИЕ ОБЪЕКТА',
        editable: true,
        params: [
          {
            label: 'ТИП НАРУШЕНИЯ',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.violationTypes(),
              placeholder: 'Выберите тип нарушения'
            },
            property: 'violation_type_id'
          },
          {
            label: 'УЧЕТНЫЙ НОМЕР ОБЪЕКТА И ДАТА ПОСТАНОВКИ НА УЧЕТ',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              disabled: true,
              placeholder: ''
            },
            property: 'obj_registration_number',
            type: 'form_generator',
            required: ['obj_registration_date']
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-20',
            componentAttrs: {
              disabled: true,
              name: 'obj_registration_date',
              placeholder: ''
            },
            property: 'obj_registration_date',
            type: 'form_generator'
          },
          {
            label: 'ГО / МР',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              disabled: object.obj_registration_number,
              items: data.areas()
            },
            property: 'area_id',
            type: 'form_generator'
          },
          {
            label: 'РАЙОН ОБЪЕКТА',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.districts(areaId),
              placeholder: 'Выберите район',
              disabled:
                !data.districts(areaId).length == 1 ||
                areaId == -1 ||
                object.obj_registration_number
            },
            property: 'address_district_id',
            type: 'form_generator'
          },
          {
            label: 'АДРЕС ОБЪЕКТА',
            component: 'auto-address',
            fieldClass: 'mb-20',
            componentAttrs: {
              object: object,
              placeholder: '',
              areaId: areaId,
              disabled: object.obj_registration_number
            },
            property: 'address_full',
            type: 'form_generator'
          },
          {
            label: 'ОПИСАНИЕ АДРЕСА',
            component: 'uiTextArea',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: 'Введите описание',
              rows: 3
            },
            property: 'address_info',
            type: 'form_generator'
          },
          {
            component: 'mapPlaceAsot',
            fieldClass: 'mb-10',
            componentAttrs: {
              polygon: data.getPolygon(object.area_id),
              disabled: object.obj_registration_number != null,
              center: geoPoint || data.getPolygon(object.area_id, true),
              zoom: getMapZoom(),
              types: ['polygon'],
              defaultType: 'polygon'
            },
            property: 'geo_polygon'
          },
          {
            label: 'КАДАСТРОВЫЙ НОМЕР ЗЕМЕЛЬНОГО УЧАСТКА',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: 'Введите кадастровый номер земельного участка'
            },

            property: 'place_cadastral_number'
          },
          {
            label: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.permittedUseKindListGroup(),
              options: true,
              placeholder: 'Выберите вид разрешенного использования'
            },
            property: 'permitted_use_kind'
          },
          {
            label: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ, ОПИСАНИЕ',
            component: 'uiTextArea',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: 'Введите описание',
              rows: 3
            },

            property: 'permitted_use_kind_description'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_permitted_use',
            componentAttrs: {
              tooltipText: 'ПОДТВЕРЖДЕНИЕ ВИДА РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ',
              proxyData: getFiles('nto_private_place_permitted_use'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_permitted_use'
            },
            type: 'form_generator'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'ВЛАДЕЛЕЦ ОБЪЕКТА'
            }
          },
          {
            label: 'ИНН',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: ''
            },

            property: 'owner_object_inn'
          },
          {
            label: 'Наименование',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: ''
            },

            property: 'owner_object_name'
          },
          {
            label: 'Статус',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.getDirectoriesList(3),
              placeholder: 'Выберите статус'
            },

            property: 'owner_object_status'
          },
          {
            _if: object => {
              return object.owner_object_status === 10;
            },
            label: 'Организационно-правовая форма',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.getDirectoriesList(2),
              placeholder: 'Выберите организационно-правовуя форму'
            },

            property: 'owner_object_opf'
          },
          {
            label: 'Должностное лицо владельца объекта',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: ''
            },

            property: 'owner_object_official'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'Владелец земельного участка'
            }
          },
          {
            label: 'ИНН',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: 'Введите ИНН'
            },
            property: 'owner_place_inn'
          },
          {
            label: 'Наименование',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: ''
            },

            property: 'owner_place_name'
          },

          {
            label: 'Статус',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.getDirectoriesList(3),
              placeholder: 'Выберите статус'
            },

            property: 'owner_place_status'
          },

          {
            _if: object => {
              return object.owner_place_status === 10;
            },
            label: 'Организационно-правовая форма',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.getDirectoriesList(2),
              placeholder: 'Выберите организационно-правовуя форму'
            },

            property: 'owner_place_opf'
          },
          {
            label: 'ДОЛЖНОСТНОЕ ЛИЦО ВЛАДЕЛЬЦА ЗЕМЕЛЬНОГО УЧАСТКА',
            component: 'uiInput',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: ''
            },

            property: 'owner_place_official'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_confirming_ownership',
            componentAttrs: {
              tooltipText: 'ПРАВО СОБСТВЕННОСТИ НА ЗЕМЕЛЬНЫЙ УЧАСТОК',
              proxyData: getFiles('nto_private_place_confirming_ownership'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_confirming_ownership'
            },
            type: 'form_generator'
          },
          {
            label: 'Тип объекта и вид объекта',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(4),
              placeholder: 'Выберите статус'
            },
            property: 'object_type'
          },
          {
            label: 'ДОПОЛНИТЕЛЬНОЕ ОПИСАНИЕ ТИПА ОБЪЕКТА И ВИДА ОБЪЕКТА',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              //modelValue: object.object_type_description || '',
              rows: 3
            },

            property: 'object_type_description'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ НЕЗАКОННОГО ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              placeholder: 'Введите номер'
            },
            property: 'audit_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              name: 'audit_doc_date'
            },
            property: 'audit_doc_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_audit_doc_files',
            componentAttrs: {
              disabled_edit: object.guid,
              tooltipText: 'АКТ ПРОВЕРКИ',
              proxyData: getFiles('audit_doc_files'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_files'
            },
            type: 'form_generator'
          },
          {
            component: 'editPhotos',
            property: 'file_audit_doc_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: '',
              proxyData: getFiles('audit_doc_photos'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_photos'
            }
          },
          {
            component: 'uiDatePicker',
            label: 'ДАТА ВЫЯВЛЕНИЯ',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'detection_date'
            },
            property: 'detection_date'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'Отметка о функционировании торгового объекта'
            }
          },
          {
            label: 'Функционирует',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: '',
              items: data.object_functioning_marks()
            },
            fieldClass: 'mb-20',

            property: 'object_functioning_mark'
          },
          {
            label: 'Площадь земельного участка, кв.м',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'land_area'
          },
          {
            label: 'Количество машино-мест, всего',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'parking_place_total_cnt'
          },
          {
            label:
              'Количество машино-мест, в т.ч. для парковки специальных автотранспортных средств инвалидов',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'parking_place_with_disabled_cnt'
          }
        ]
      },
      {
        editable: true,
        title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА',
        params: [
          {
            label: 'Соблюдение правил благоустройства',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              //modelValue: object.beautification_rules || '',
              items: data.getDirectoriesList(23),
              placeholder: ''
            },

            property: 'beautification_rules'
          },
          {
            label:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ ПО НЕ РАЗМЕЩЕНИЮ ОБЪЕКТА ПРИВОДЯЩЕЕ К СОКРАЩЕНИЮ СВОБОДНОГО ДЛЯ ПЕШЕХОДНОГО ДВИЖЕНИЯ ТРОТУАРА ДО ШИРИНЫ МЕНЕЕ 3 МЕТРОВ',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.compliance_crosswalk_requirements || '',
              name: 'compliance_crosswalk_requirements',
              items: data.boolRadio()
            },
            fieldClass: 'mb-20',

            property: 'compliance_crosswalk_requirements'
          },
          {
            label:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ ПО НЕ РАЗМЕЩЕНИЮ ОБЪЕКТА НА ПЛОЩАДКАХ (ДЕТСКИХ, ОТДЫХА, СПОРТИВНЫХ), НА ГАЗОНАХ, ЦВЕТНИКАХ, А ТАКЖЕ НА ОБЪЕКТАХ ОЗЕЛЕНЕНИЯ',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.compliance_fields_requirements || '',
              name: 'compliance_fields_requirements',
              items: data.boolRadio()
            },
            fieldClass: 'mb-20',

            property: 'compliance_fields_requirements'
          },
          {
            label:
              'СОБЛЮДЕНИЕ ВИДА РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ ЗЕМЕЛЬНОГО УЧАСТКА ПРИ РАЗМЕЩЕНИИ ОБЪЕКТА',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.compliance_kind_requirements || '',
              name: 'compliance_kind_requirements',
              items: data.boolRadio()
            },
            fieldClass: 'mb-20',

            property: 'compliance_kind_requirements'
          },
          {
            label:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ ПО НЕ РАЗМЕЩЕНИЮ ОБЪЕКТА НА ПРИДОМОВОЙ ТЕРРИТОРИИ',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.compliance_house_territory_requirements || '',
              name: 'compliance_house_territory_requirements',
              items: data.boolRadio()
            },
            fieldClass: 'mb-20',

            property: 'compliance_house_territory_requirements'
          },
          {
            label:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ О РАЗМЕЩЕНИИ ОБЪЕКТА ДАЛЕЕ 15 МЕТРОВ ОТ ФАСАДОВ И ОКОН ЗДАНИЙ',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.compliance_facade_requirements || '',
              name: 'compliance_facade_requirements',
              items: data.boolRadio()
            },
            fieldClass: 'mb-20',

            property: 'compliance_facade_requirements'
          },
          {
            label:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ О НЕ РАЗМЕЩЕНИИ ОБЪЕКТА В ГРАНИЦАХ ОБЪЕКТОВ КУЛЬТУРНОГО НАСЛЕДИЯ',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.compliance_cultural_heritage_requirements || '',
              name: 'compliance_cultural_heritage_requirements',
              items: data.boolRadio()
            },
            fieldClass: 'mb-20',

            property: 'compliance_cultural_heritage_requirements'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРОЧЕЕ',
        params: [
          {
            label: 'Место подключения к электрическим сетям',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(7),
              placeholder: 'Выберите место подключения к электрическим сетям'
            },

            property: 'place_electric_networks'
          },
          {
            label: 'Место подключения к электрическим сетям,адрес здания',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: ''
            },

            property: 'place_electric_networks_address'
          },
          {
            label: 'Статус подключения к электрическим сетям',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(8),
              placeholder: 'Выберите статус подключения к электрическим сетям'
            },

            property: 'place_electric_networks_status'
          },
          {
            label:
              'Статус направления письма в энергоснабжающую организацию о самовольном подключении к электрическим сетям',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(9),
              placeholder: 'Выберите статус направления письма'
            },

            property: 'place_electric_networks_mail_status'
          },
          {
            label: 'Отметка о размещении на первой линии улицы',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(10),
              placeholder: 'Выберите отметку о размещении на первой линии улицы'
            },

            property: 'place_first_line_street'
          },
          {
            label:
              'Статус направления письма в Госпожнадзор МЧС России по Пермскому краю',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(11),
              placeholder: 'Выберите статус направления письма'
            },

            property: 'mcs_mail_status'
          },
          {
            label: 'Мероприятия, проводимые МЧС России по Пермскому краю',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.getDirectoriesList(12),
              placeholder:
                'Выберите мероприятия, проводимые МЧС России по Пермскому краю'
            },

            property: 'mcs_actions'
          },
          {
            label: 'Дата выдачи предостережения от МЧС',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'date_of_caution'
            },

            property: 'date_of_caution'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_warning',
            componentAttrs: {
              tooltipText: 'Предостережение от МЧС',
              proxyData: getFiles('nto_private_place_warning'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_warning'
            },
            type: 'form_generator'
          },
          {
            label: 'Дата предоставления материалов на заседании по ЧС',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'meeting_submission_date'
            },

            property: 'meeting_submission_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_materials_emergency_meeting',
            componentAttrs: {
              tooltipText: 'Материалы на заседании по ЧС',
              proxyData: getFiles(
                'nto_private_place_materials_emergency_meeting'
              ),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_materials_emergency_meeting'
            },
            type: 'form_generator'
          }
        ]
      },
      {
        editable: true,
        title: 'ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ',
        params: [
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'Отметка о демонтаже'
            }
          },
          {
            label: 'Демонтирован',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.mark_dismantling || '',
              name: 'mark_dismantling',
              items: data.boolRadio()
            },
            required: ['dismantling_date'],
            fieldClass: 'mb-20',
            property: 'mark_dismantling'
          },
          {
            label: 'Дата демонтажа',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'dismantling_date'
            },

            property: 'dismantling_date'
          },
          {
            label: 'Примечание',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              //modelValue: object.description || '',
              rows: 3
            },

            property: 'description'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_voluntary_dismantling_docs',
            componentAttrs: {
              tooltipText: 'Акт добровольного демонтажа',
              proxyData: getFiles(
                'nto_private_place_voluntary_dismantling_docs'
              ),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_voluntary_dismantling_docs'
            },
            type: 'form_generator'
          },
          {
            component: 'editPhotos',
            property: 'file_nto_private_place_voluntary_dismantling_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: false,
              title: '',
              proxyData: getFiles(
                'nto_private_place_voluntary_dismantling_photos'
              ),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_voluntary_dismantling_photos'
            }
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ (ПО РЕШЕНИЮ КЧС)',
        params: [
          {
            label: 'Дата решения КЧС о демонтаже объекта',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'dismantling_decision_date'
              //modelValue: object.dismantling_decision_date || '',
            },
            required: [
              'dismantling_decision_number',
              'file_nto_private_place_decision_dismantle_objects'
            ],

            property: 'dismantling_decision_date'
          },
          {
            label: 'Номер решения КЧС о демонтаже объекта',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.dismantling_decision_number || '',
            },

            property: 'dismantling_decision_number'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_decision_dismantle_objects',
            componentAttrs: {
              border_top: true,
              tooltipText: 'Решение КЧС о демонтаже объекта',
              proxyData: getFiles(
                'nto_private_place_decision_dismantle_objects'
              ),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_decision_dismantle_objects'
            },
            type: 'form_generator'
          },
          {
            label: 'Дата уведомления о решении КЧС и требовании о демонтаже',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'coes_notification_decision_dismatle_date'
              //modelValue: object.coes_notification_decision_dismatle_date || '',
            },
            property: 'coes_notification_decision_dismatle_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_coes_decisions',
            componentAttrs: {
              border_top: true,
              tooltipText: 'Уведомление  о демонтаже объекта',
              proxyData: getFiles('nto_private_place_coes_decisions'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_coes_decisions'
            },
            type: 'form_generator'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ (ПО РЕШЕНИЮ СУДЕБНЫХ ОРГАНОВ)',
        params: [
          {
            label: 'Дата подачи иска на владельца объекта',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'date_claim_object_owner'
              //modelValue: object.date_claim_object_owner || '',
            },
            required: ['file_nto_private_place_claim_object_owner'],
            property: 'date_claim_object_owner'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_claim_object_owner',
            componentAttrs: {
              border_top: true,
              tooltipText: 'Иск на владельца объекта',
              proxyData: getFiles('nto_private_place_claim_object_owner'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_claim_object_owner'
            },
            type: 'form_generator'
          },
          {
            label: 'Дата решения судебных органов',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'judicial_decision_date'
              //modelValue: object.judicial_decision_date || '',
            },
            property: 'judicial_decision_date'
          },
          {
            label: 'Дата окончания срока обжалования',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'property'
              //modelValue: object.appeal_deadline_date || '',
            },
            property: 'appeal_deadline_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_nto_private_place_judicial_decision',
            componentAttrs: {
              border_top: true,
              tooltipText: 'Решение судебных органов',
              proxyData: getFiles('nto_private_place_judicial_decision'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_judicial_decision'
            },
            type: 'form_generator'
          },
          {
            label: 'Дата окончания срока принудительного демонтажа',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'expiration_date'
              //modelValue: object.expiration_date || '',
            },
            property: 'expiration_date'
          },
          {
            label: 'Дата размещения уведомления на объекте',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'nto_notification_date'
              //modelValue: object.nto_notification_date || '',
            },
            property: 'nto_notification_date'
          },
          {
            component: 'editPhotos',
            property: 'file_nto_private_place_nto_notification',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: 'Размещение уведомления на объекте, ФОТОГРАФИИ',
              proxyData: getFiles('nto_private_place_nto_notification'),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_nto_notification'
            }
          },
          {
            label: 'Отметка о принудительном демонтаже',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object || 'forced_dismantling_mark',
              name: 'forced_dismantling_mark',
              items: data.boolRadio()
            },
            fieldClass: 'mb-10',
            property: 'forced_dismantling_mark'
          },
          {
            label: 'Дата принудительного демонтажа',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'forced_dismantling_date'
              //modelValue: object.forced_dismantling_date || '',
            },
            property: 'forced_dismantling_date'
          },
          {
            component: 'editPhotos',
            property: 'file_nto_private_place_forced_dismantling_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: 'Принудительный демонтаж, Фотографии',
              proxyData: getFiles(
                'nto_private_place_forced_dismantling_photos'
              ),
              allowMultiple: 'true',
              uploadFolder: 'nto_private_place_forced_dismantling_photos'
            }
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ДОГОВОРЕ АРЕНДЫ',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: ''
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'agreement_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'agreement_date'
            },
            property: 'agreement_date'
          },
          {
            label: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ ЗЕМЕЛЬНОГО УЧАСТКА',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.landUsageTypes(),
              placeholder: 'Выберите вид использования'
            },
            property: 'agreement_land_usage_type_id'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О НАРУШЕНИИ УСЛОВИЙ ДОГОВОРА АРЕНДЫ'
            }
          },
          {
            label: 'ОПИСАНИЕ НАРУШЕНИЙ УСЛОВИЙ ДОГОВОРА',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              rows: 3
            },
            property: 'lease_violation_desc'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА СОСТАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'lease_violation_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'lease_violation_doc_date'
            },
            property: 'lease_violation_doc_date'
          },
          {
            label: 'СРОК ДЛЯ УСТРАНЕНИЯ НАРУШЕНИЙ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'lease_violation_fix_date'
            },
            property: 'lease_violation_fix_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Сведения об устранении нарушений условий договора аренды'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Акт проверки исполнения'
            }
          },
          {
            label: 'Номер и дата Составления',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'fix_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'fix_doc_date'
            },
            property: 'fix_doc_date'
          },
          {
            label: 'результаты проверки исполнения',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите результат',
              rows: 3
            },
            property: 'fix_doc_result'
          }
        ]
      }
    ];
  }
};
